$orderTableMinHeight: 540px;

.Table {
  min-height: $orderTableMinHeight;
}

.column {

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &[data-id=shop_url] {
    max-width: rem(110px);
  }

  &[data-id=status] {
    max-width: 90px;
  }

  //&[data-id=courier] {
  //
  //}
  //
  //&[data-id=lastCheckPoint] {
  //  //max-width: rem(200px);
  //  max-width: 15vw;
  //}
  //
  //&[data-id=transit] {
  //  display: flex;
  //}
  //
  //&[data-id=orderDate] {
  //
  //}
  //
  //&[data-id=shipmentStatus] {
  //  max-width: rem(160px);
  //}
}

.CustomEmptyState__Wrapper {
  margin: -16px;
  display: flex;
  height: $orderTableMinHeight;
  flex-direction: column;
}

.EmptySearchState__Wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}


@media screen and (max-width: 455px) {
  .column {
    width: 100px !important;
    white-space: normal !important;
    overflow-wrap: break-word !important;
    overflow: unset !important;
    text-overflow: unset !important;
  }
}