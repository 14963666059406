.stepAction-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .Polaris-Button--plain > .Polaris-Button__Content {
    font-weight: 500;
  }
}

.Polaris-TextField__Input {
  line-height: 2rem;
}