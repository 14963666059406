.FiltersWrapper {
  padding: 16px;

  +.HeaderOuterWrapper{
    margin-top: -10px;
  }
}

.HeaderContentWrapper {
  display: flex;
}

.HeaderWrapper {
  padding: 10px 16px;
}

.HeaderTitleWrapper {
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  align-self: center;
}

.SortWrapper {
  margin-left: 16px;
}

.ReferralsTable {
  min-height: 540px;
}

.Pagination__Wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
  padding-top: 10px;  // 滚动条占了 14px
}


@media screen and (max-width:436px) {
  .SortWrapper {
      width: 52% !important;
  }
}
