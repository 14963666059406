.CardSection__Allocation {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 4px;
}

.Chart__Subhead {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 600;
}

.AlignLeft {
  text-align: left;
  padding-right: 24px;
  line-height: 2.6;
}

.AlignRight {
  text-align: right;
  padding-right: 24px;
  line-height: 2.6;
}
