.pagination-section {
  position: relative;
}

.pagination-text {
  color: #637381;
}

.payouts {
  text-align: center;
}

.payouts_spinner {
  margin-top: 140px;
  margin-bottom: 140px;
}

.PayoutsRecords-BottomTip {
  margin-top: .8rem;
}

.PayoutsRecords-BottomTip__Title {
  color: #6D7175;
}

.PayoutsRecords-BottomTip__Item {
  color: #6D7175;

  +.PayoutsRecords-BottomTip__Item {
    margin-top: .4rem;
  }
}


@media screen and (max-width: 455px) {
  .Table-th-box {
    width: 105px !important;
    white-space: normal;
    word-break: break-word;
  }

  .section-content {
    .Polaris-Card__Section {
      padding: 1rem !important;
    }
  }
  .PayoutsRecords {
    thead {
      th:first-child  {
        white-space: normal !important;
        word-wrap: break-word;
      }
    }
    .Payout-date-table-box {
      width: 105px !important;
      white-space: normal;
      word-break: break-word;
    }
  }
}