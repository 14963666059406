.Polaris-TopBar-Menu__Activator {
  margin-left: .8rem;
}

.Polaris-Connected__Item:not(:first-child) {
  margin-left: 1rem;
}

._Switcher {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-start;
  padding: 0 .8rem
}

._ActivatorLink {
  padding: .6rem .8rem;
  border-radius: var(--p-border-radius-base);
  cursor: pointer;

  &:hover {
    background-color: var(--p-surface-hovered);
    color: var(--p-text-on-hover);
  }
}

.ActivatorDetails {
  display: flex;
  align-items: center;

  img {
    height: 36px;
  }

  .Polaris-Heading {
    margin-left: 12px;
    text-decoration: none;
  }
}

.page-warp {
  margin: 1.6rem auto 3.2rem;
  padding: 0 2rem;
  max-width: 120rem + 4rem;
}

.page--fullWidth {
  max-width: unset;
}

.page-title {
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;

  + .page-title {
    margin-top: 2rem;
  }
}

.section {
  + .section {
    margin-top: 3.2rem;
  }
}

.section-header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  + .section-header-subtitle {
    margin-top: .4rem;
  }
}

.section-content {
  margin-top: .8rem;
}

.PP-Table {
  /* ++ Sticky 分页 */
  //.Polaris-IndexTable__ScrollBarContainer {
  //  bottom: 6.3rem;
  //}

  //.Polaris-Card__Section {
  //  position: sticky;
  //  bottom: 0;
  //  background-color: var(--p-surface);
  //}

  .Polaris-IndexTable__LoadingPanel + .Polaris-IndexTable__EmptySearchResultWrapper {
    margin-top: 5.2rem;
  }

  .Polaris-Card__Section {
    padding-top: .7rem;
  }

  .Polaris-IndexTable__TableHeading {
    padding: 1.6rem 2rem;
  }

  .Polaris-IndexTable__TableCell {
    padding: 1.6rem 2rem;
  }
}

.PP-IndexTable--Empty {
  .PP-EmptySearch {
    padding: 3.2rem 2rem 1.6rem;
    box-shadow: 0 -0.1rem 0 var(--p-divider);
  }
}

.PP-FormCard {
  .Polaris-Card__Header {
    padding: 1.6rem 2rem;
  }

  .Polaris-Card__Section {
    box-shadow: inset 0 1px 0 0 #e1e3e5;
    padding-top: 1.6rem;
  }
}

.PP-FormCard-Footer {
  margin-top: 2rem;
}

.accountText {
  position: relative;

  .tip {
    display: block;
    background: #cb0c0c;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    top: 0px;
    left: 58px;
    position: absolute;
  }
}


@media screen and (max-width: 768px) {
  .Polaris-Frame__TopBar {
    .Polaris-TopBar__ContextControl {
      display: block !important;
    }
    .Polaris-TopBar-Menu__ActivatorWrapper {
      margin-right: 4px !important;
    }
  }
}