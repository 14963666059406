//.login {
//  display: flex;
//  margin: 0 auto;
//  height: 100%;
//  align-items: center;
//  justify-content: center;
//
//  &-card-wrap {
//    width: 600px;
//
//    .subtitle {
//      margin-top: 8px;
//    }
//
//    label {
//      font-weight: 400 !important;
//    }
//  }
//
//  .Polaris-FormLayout__Item {
//    margin-top: 2rem;
//  }
//
//  .Polaris-Button {
//    &--primary {
//      height: 4rem;
//    }
//
//    &__Content {
//      line-height: 2rem;
//    }
//  }
//}


//.login-form-wrap {
//  margin-top: 48px + 16px; // 16px用来填补组件的负边距
//}

.forgetPassword-wrap {
  margin-top: 4px - 20px; // -20px用来抵消组件默认边距
}

.registerButton-wrap {
  margin-top: .8rem;
}