body {
  font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

#root {
  background-color: #f5f5f6;
}

#Page-Referrals {
  .Polaris-IndexTable__TableCell {
    height: 50px;
  }
}

// 移动端点击按钮有阴影问题修复
html,body {
  -webkit-tap-highlight-color:transparent !important;
}

// 客服层级问题

.intercom-lightweight-app, .intercom-namespace .intercom-1ryjg1e {
  z-index: 99 !important;
}

.Polaris-TextField {
  &__Input {
    &:-webkit-autofill {
      border-color: var(--p-border-subdued);
      border-top-color: var(--p-border-shadow);
    }
  }

  &--error {
    &__Input {
      &:-webkit-autofill {
        border-color: var(--p-border-critical);
      }
    }
  }
}

#password:-webkit-autofill,
#confirmpwd:-webkit-autofill,
#new_password:-webkit-autofill,
#confirm_new_password:-webkit-autofill {
  border-color: var(--p-border-subdued);
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-color: var(--p-border-shadow);
}

.Polaris-TextField--error {
  #password:-webkit-autofill,
  #confirmpwd:-webkit-autofill,
  #new_password:-webkit-autofill,
  #confirm_new_password:-webkit-autofill {
    border-color: var(--p-border-critical);
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}