.login {
  display: flex;
  margin: 0 auto;
  height: 100%;
  align-items: center;
  justify-content: center;

  &-card-wrap {
    width: 600px;

    label {
      font-weight: 400 !important;
    }
  }

  .Polaris-FormLayout__Item {
    margin-top: 2rem;
  }

  .Polaris-Button {
    &--primary {
      height: 4rem;
    }

    &__Content {
      line-height: 2rem;
    }
  }

  .Polaris-TextContainer {
    font-size: 1.6rem;
  }

  .footerAction-wrap {
    margin-top: .8rem;
  }


  .Polaris-Card__Header {
    padding: 3.2rem 3.2rem 0;
    text-align: center;

    .fLogo {
      width: 300px;
      vertical-align: top;
    }

    .subtitle {
      margin-top: 8px;
      color: var(--p-text-subdued);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .Polaris-Card__Header + .Polaris-Card__Section {
    padding: 4.8rem 3.2rem 1.6rem;
  }

  // 只值存在一个元素时生效，否则被下面样式覆盖
  .Polaris-Card__Section:last-child {
    padding-bottom: 4.8rem;
  }

  .Polaris-Card__Section + .Polaris-Card__Section {
    padding: 1.6rem 3.2rem;
  }
}