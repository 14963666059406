.Header {
  display: flex;
  margin-bottom: -4px;
  justify-content: space-between;
  align-items: flex-start;
}

.tableDate {
  width: 540px;

  @media screen and (max-width: 640px) {
    width: 100%;
  }
}
