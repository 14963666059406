.forgot-password1{
    margin: 0 auto;
    position: relative;
    top: 12%;
    width: 620px;
}

.forgot-password1 h1{
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 30px;
}

.forgot-password1 h2{
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #6D7175;
}

.forgot-password1 .h2_div{
    text-align: center;
    margin: 6.1px 0px 0px 0px;
}

.forgot-password1 .logo {
    width: 300px;
    height: 39.89px;
    margin: 32px 0px 0px 0px;
}

.forgot-password1 .Polaris-Card__Section {
    padding: 0rem;
}
.forgot-password1 .Polaris-InlineError {
    display: flex;
    margin: 0px 0px 0px 32px;
    /*color: var(--p-text-critical);*/
    /*fill: var(--p-icon-critical);*/
}

.forgot-password1 .Polaris-Labelled__LabelWrapper {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 0.4rem;
    padding: 0px 32px 0px 32px;
}

.forgot-password1 .Polaris-TextField {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    border: none;
    text-transform: initial;
    letter-spacing: initial;
    position: relative;
    display: flex;
    align-items: center;
    cursor: text;
    width: 534px;
    margin: 0 32px 0 32px;
}

.forgot-password1 .Polaris-Button {
    background: #007556;
    border: 1px solid #008060;
    box-sizing: border-box;
    border-radius: 5px;
    width: 536px;
    height: 40px;
    position: relative;
    margin: 21px 32px 0 32px;
}

.forgot-password1 p{
    cursor: pointer;
    font-size: 1.6rem;
    margin: 8px 0 0 32px;
    color: #007ACE;
    width: 106px;
}

.forgot-password1 .Polaris-Card{
    height: 349px;
    width: 600px;
}

.forgot-password1 .logo_div {
    text-align: center;
    margin: 0px 0px 0px 0px;
}
.forgot-password1 .logo{
    width: 300px;
    height: 40px;
}

.forgot-password1 .Polaris-FormLayout__Item{
    flex: 1 1 22rem;
    margin-top: 0rem;
}

.forgot-password1 .text_style{
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    color: #000000;
    margin: 0 32px 48px 32px;
}

.forgot-password1 h2{
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #6D7175;
    margin-bottom: 48px;
}