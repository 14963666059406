a.HelpCenterItem {
  display: block;
  text-decoration: unset;
  color: inherit;
}

.HelpCenterItem {
  position: relative;
  cursor: pointer;

  & + & {
    margin: 4px 0;
  }

  &__IconWrapper {
    margin-right: 20px;
    color: inherit;
    text-decoration: none;
  }

  &__Container {
    position: relative;
    padding: 12px 20px;
    z-index: 2;
    display: flex;
    align-items: flex-start;
  }

  &__Title {
    margin-bottom: 8px;
  }

  &:hover {
    background-color: var(--p-surface-hovered);

    svg {
      fill: #006FBB;
    }
  }

  &:hover &__Title {
    color: #006FBB;
  }
}

.HelpCenterWrapper {
  padding: 12px 0 10px;
}

.TextContainer--spacing4 {
  >:not(:first-child) {
    margin-top: 4px;
  }
}