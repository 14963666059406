.table {
  width: 100%;
  margin-left: -3px !important;

  thead {
    th {
      font-weight: 500;
      text-align: left;
    }
  }
}