.data-number {
  text-align: center;

  p {
    display: inline-block;

    span:nth-child(1) {
      margin-bottom: 4px;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
    }

    span:nth-child(2) {
      font-size: 14px;
    }
  }

  span {
    display: block;
  }
}

.apps-select {
  display: flex;
}

.apps-select div {
  margin-right: 10px;
}

.total-number-section {
  margin-top: 40px;
}

.referrals-section {
  margin-top: 40px;
}

.pagination-section {
  .Polaris-ButtonGroup--segmented {
    justify-content: center;
  }
}

.pagination-text {
  line-height: 20px;
  color: #6D7175;
}

.conversion_spinner {
  margin-top: 140px;
  margin-bottom: 140px;
  text-align: center;
}

.Pagination__Section {
  margin-top: 1.6rem;
}

.section-referralLink-content {
  margin-top: 2rem;
}